<template>
  <div class="about">
    <div v-if="isError">
      <h2>{{errorMessage}}</h2>
      <a href="/" title="Home page">Go Home</a>
    </div>
    <OnBoardingLoader v-else
      :title="'Logging you in...'"
      :description="'We are validating your credentials.'"
    />
  </div>
</template>
<script>
import OnBoardingLoader from '@/components/OnBoardingLoader.vue';

export default {
  name: 'loginresponse',
  components: {
    OnBoardingLoader,
  },
  data() {
    return {
      isError: false,
      errorMessage: '',
    };
  },
  created() {
    const { token } = this.$route.query;
    console.log('token', token);
    if (token === undefined || token === null || token === '') {
      this.isError = true;
      this.errorMessage = 'Token Missing';
    } else if (token === 'access_denied') {
      this.isError = true;
      this.errorMessage = 'You are not authorised to access this page!';
    } else {
      this.$store
        .dispatch('user/updateAccessToken', { token })
        .then(() => {
          const nextUrl = this.$route.query.next_url || '/v2/';
          this.$router.push({ path: nextUrl });
        })
        .catch((err) => console.log(err));
    }
  },
};
</script>
